@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #fff;
  color: #333;
  transition: background-color 0.4s ease, color 0.4s ease;
  z-index: 1000;
}

button:hover {
  background-color: #333; /* Dark background on hover */
  color: #fff; /* Light text on hover */
}

#loadMore:hover {
  color: #fff !important;
}

button img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

@keyframes colorFlow {
  0% { color: #007BFF; }
  25% { color: #28a745; }
  50% { color: #6f42c1; }
  75% { color: #28a745; }
  100% { color: #007BFF; }
}

.madimi-one-regular {
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.header {
  font-size: 10rem;
  color: #111;
  position: absolute;
  top: 9rem;
  animation: colorFlow 10s ease-in-out infinite;
}

.table-container {
  position: absolute; 
  top: 100vh;
  width: 100%;
}

table {
  width: 80%;
  margin: 0 auto; /* Center the table horizontally */
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  top: 10px;
  /* padding: 8px; */
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #eaeaea;
}

.loading-icon {
  margin-top: 20px; /* Adjust as needed */
  font-size: large;
}